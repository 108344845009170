import React from "react";
import BlockChainConnection from "./BlockChainConnection";

const App = () => {
    return(
        <div>
            <BlockChainConnection />
        </div>
    )
}

export default App