export var firstPart = [
"The secrets to",
"The first steps towards",
"The keys to",
"The foundations of",
"The central components of",
"The most important ingredients of",
"The key elements of",
"The pillars of"
];

export var secondPart = [
'financial success are',
'happiness are',
'excellence are',
'achieving enlightenment are',
'good times are',
'finding a girlfriend are',
'finding a boyfriend are',
'a fulfilling life are',
'a happy marriage are',
'stable relationships are',
'longevity are',
'overcoming adversity are',
'success are',
'victory are',
'true wealth are',
'innovation are',
'prosperity are'
];

export var thirdPart = [
'transcendental meditation',
'listening to your racist uncle',
'intermittent fasting',
'Scientology',
'faith in Jesus',
'regular masturbation',
'daily yoga',
'cocaine',
'high-strength edibles',
'homebrew tequila',
'high doses of LSD',
'a strict vegan diet',
'Ecstasy',
'regular exercise',
'self-medication',
'elbow grease',
'the "withdrawal method"',
'Twitter',
'Ivermectin',
'birth control',
'Good Times Coin',
'Facebook moms groups',
'Tinder',
'ketamine',
'doing affirmations',
'visualization',
'generosity',
'positive thinking',
'ayahuasca',
'kindness'
]

export var lastPart = [
    'and', 'and', 'and', 'and' ,'and avoiding'
]